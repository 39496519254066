import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Box, TextField, Button, Typography, Alert } from '@mui/material';
import { createSubCategory } from 'src/redux/slices/category';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';

const SubCategoryCreateForm = () => {
  const { name, id } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
  const dispatch = useDispatch();
  const [subCategories, setSubCategories] = useState([{ category: Number(id), name: '', description: '' }]);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [error, setError] = useState('');

  const handleAddField = () => {
    // Validate that all fields in the current subcategories are filled
    const isValid = subCategories.every((subCategory) => subCategory.name.trim() && subCategory.description.trim());

    if (!isValid) {
      setError('Please fill in all the fields before adding a new subcategory.');
      return;
    }

    // Reset error and add a new field
    setError('');
    setSubCategories([...subCategories, { category: Number(id), name: '', description: '' }]);
  };

  const handleRemoveField = (index) => {
    const updatedSubCategories = subCategories.filter((_, i) => i !== index);
    setSubCategories(updatedSubCategories);
  };

  const handleInputChange = (index, field, value) => {
    const updatedSubCategories = [...subCategories];
    updatedSubCategories[index][field] = value;
    setSubCategories(updatedSubCategories);
  };

  const handleSubmit = async() => {
    // Validate that all fields are filled
    const isValid = subCategories.every((subCategory) => subCategory.name.trim() && subCategory.description.trim());

    if (!isValid) {
      setError('Please fill in all the fields before submitting.');
      return;
    }
    setError('');
    console.log('Submitted Subcategories:', subCategories);
    setFormSubmitted(true);
    await dispatch(createSubCategory(subCategories));  
    enqueueSnackbar('Update success!');
    navigate(`/dashboard/category/${name}/${id}`)
    // Optionally clear the form or reset state
    // setSubCategories([{ name: '', description: '' }]);
  };

  // const onSubmit = async (data) => {
  //   console.log('Form Data:', {
  //     ...data,
  //     bookingtype: bookingType,
  //   });

  //   try {
  //     const newCategory = {
  //       name: data.name,
  //       description: data.description,
  //       tags: data.tags,
  //       isBookingNeeded: data.isBookingNeeded,
  //       ...(data.isBookingNeeded ? { bookingType: bookingType } : {}),
  //       images: data.images,
  //       // Check if priority is present and not equal to 0 before including it
  //       ...(data.priority !== 0 ? { priority: data.priority } : {}),
  //     };

  //     if (currentCategory.id) {
  //       await dispatch(updatCategory(currentCategory.id, newCategory));
  //       enqueueSnackbar('Update success!');
  //     } else {
  //       await dispatch(createCategory(newCategory));
  //       enqueueSnackbar('Create success!');
  //     }

  //     // Only navigate if there were no errors during dispatch
  //     navigate(PATH_DASHBOARD.category.list);
  //     reset();
  //   } catch (error) {
  //     console.error(error);
  //     // Handle the error, e.g., display an error message to the user
  //   }
  // };


  return (
    <Box sx={{ maxWidth: 'full', margin: '0 auto', padding: '20px' }}>
      <Typography variant="h4" gutterBottom>
        Create Subcategories for {name ? name.charAt(0).toUpperCase() + name.slice(1).toLowerCase() : 'Category'}
      </Typography>

      {formSubmitted && (
        <Alert severity="success" sx={{ marginBottom: '20px' }}>
          Subcategories submitted successfully!
        </Alert>
      )}

      {error && (
        <Alert severity="error" sx={{ marginBottom: '20px' }}>
          {error}
        </Alert>
      )}

      {subCategories.map((subCategory, index) => (
        <Box
          key={index}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            marginBottom: '20px',
            padding: '10px',
            border: '1px solid #ccc',
            borderRadius: '8px',
          }}
        >
          <TextField
            label={`Subcategory name ${index + 1}`}
            variant="outlined"
            value={subCategory.name}
            onChange={(e) => handleInputChange(index, 'name', e.target.value)}
            fullWidth
          />
          <TextField
            label="Description"
            variant="outlined"
            multiline
            rows={3}
            value={subCategory.description}
            onChange={(e) => handleInputChange(index, 'description', e.target.value)}
            fullWidth
          />
          {subCategories.length > 1 && (
            <Box sx={{ textAlign: 'right' }}>
              <Button
                variant="text"
                color="error"
                onClick={() => handleRemoveField(index)}
                sx={{ textTransform: 'none', fontWeight: 'bold' }}
              >
                Remove
              </Button>
            </Box>
          )}
        </Box>
      ))}

      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
        <Button variant="outlined" onClick={handleAddField} sx={{ textTransform: 'none' }}>
          Add Subcategory
        </Button>
        <Button variant="contained" color="primary" onClick={handleSubmit} sx={{ textTransform: 'none' }}>
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default SubCategoryCreateForm;
