import { useEffect,useState } from 'react';
/* eslint-disable */
import { paramCase } from 'change-case';
import { useParams, useLocation } from 'react-router-dom';
// @mui
import { Container } from '@mui/material';
// redux
import { useDispatch, useSelector } from '../../../redux/store';
import { getCategory } from '../../../redux/slices/category';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// hooks
import useSettings from '../../../hooks/useSettings';
// components
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';

import SubCategoryCreateForm from 'src/sections/@dashboard/category/SubCategoryCreateForm';

// ----------------------------------------------------------------------
 
export default function SubCategoryCreate() {
  const { themeStretch } = useSettings();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { name,id } = useParams();
 
  const { category } = useSelector((state) => ({...state.category}));
 
  const isEdit = pathname.includes('edit');
 
  useEffect(() => {
  
    
    if(isEdit){
      dispatch(getCategory(id));
     
   
    }  
   
  }, [dispatch]);

  return (
    <Page title="Create sub category">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={!isEdit ? 'Create Sub Category' : 'Edit Sub Category'}
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            {
              name: 'Category',
              href: PATH_DASHBOARD.category.root,
            },
            { name: !isEdit ? 'Sub Category' : name },
          ]}
        />

        <SubCategoryCreateForm isEdit={isEdit} currentCategory={category} />
      </Container>
    </Page>
  );
}
