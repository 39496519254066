import { paramCase, sentenceCase } from 'change-case';
/* eslint-disable */
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import {
  Button,
  Box,
  Card,
  Table,
  TableRow,
  Checkbox,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Alert,
  TableHead,
} from '@mui/material';
// redux
import { useDispatch, useSelector } from '../../../redux/store';
import { getCategories,deleteCategory,deleteManyCategory, getCategory, deleteSubCategory } from '../../../redux/slices/category';
// utils
import { fDate } from '../../../utils/formatTime';
import { fCurrency } from '../../../utils/formatNumber';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// hooks
import useSettings from '../../../hooks/useSettings';
// components
import Page from '../../../components/Page';
import Label from '../../../components/Label';
import Image from '../../../components/Image';
import Scrollbar from '../../../components/Scrollbar';
import SearchNotFound from '../../../components/SearchNotFound';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import Iconify from '../../../components/Iconify';
import swal from 'sweetalert';

// sections
import {
  CategoryMoreMenu,
  CategoryListHead,
  CategoryListToolbar,
} from '../../../sections/@dashboard/category/category-list/index';
import SubCategoryMoreMenu from 'src/sections/@dashboard/category/category-list/SubCategoryMoreMenu';
import SubCategoryListActions from 'src/sections/@dashboard/category/category-list/subCategoryListActions';
// ../../sections/@dashboard/e-commerce/category-list/
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'priority', label: 'Sub Category', alignRight: false },
  { id: 'priority', label: 'Order', alignRight: false },
  { id: 'createdAt', label: 'Create at', alignRight: false },
  { id: 'action',label:'Action',alignRight:true },
];

// ----------------------------------------------------------------------

export default function SubCategoryList() {
    const { themeStretch } = useSettings();
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const { name, id } = useParams();
  
    const { category } = useSelector((state) => ({ ...state.category }));
  
    const isEdit = pathname.includes('edit');
  
    const [subcategoryData, setSubcategoryData] = useState([]);
  
    useEffect(() => {
      dispatch(getCategory(id));
    }, [dispatch, id]);
  
    useEffect(() => {
      if (category?.subcategory) {
        setSubcategoryData(category.subcategory);
      }
    }, [category]);
  
    const handleEdit = (subcategoryId) => {
      console.log(`Edit subcategory with ID: ${subcategoryId}`);
    };
  
    const handleDelete = (subcategoryId) => {
      swal({
        title: 'Are you sure?',
        text: 'Once deleted, you will not be able to recover this record!',
        icon: 'warning',
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          dispatch(deleteSubCategory(subcategoryId));
          enqueueSnackbar('Subcategory deleted successfully', { variant: 'success' });
  
          // Update the local state to remove the deleted subcategory
          setSubcategoryData((prevData) =>
            prevData.filter((subcategory) => subcategory.id !== subcategoryId)
          );
        }
      });
    };
  
    return (
      <Page title="Subcategory List">
        <Container maxWidth={themeStretch ? false : 'lg'}>
          <HeaderBreadcrumbs
            action={
              <Button
                variant="contained"
                component={RouterLink}
                
                to={`${PATH_DASHBOARD.category.root}/${paramCase(name)}/${id}/sub-category`}
                startIcon={<Iconify icon={'eva:plus-fill'} />}
              >
                New Subcategory
              </Button>
            }
            heading="Subcategory List"
            links={[
              { name: 'Dashboard', href: PATH_DASHBOARD.root },
              { name: 'Category', href: PATH_DASHBOARD.category.root },
              { name: 'Subcategory List' },
            ]}
          />
          <Card>
            <Scrollbar>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Id</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell>Created At</TableCell>
                      <TableCell align="right">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {subcategoryData && subcategoryData.length > 0 ? (
                      subcategoryData.map((subcategory, index) => (
                        <TableRow key={subcategory.id}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{subcategory.name}</TableCell>
                          <TableCell>{subcategory.description}</TableCell>
                          <TableCell>{fDate(subcategory.created_at)}</TableCell>
                          <TableCell align="right">
                            <SubCategoryListActions
                              name={name}
                              id={subcategory.id}
                              onDelete={() => handleDelete(subcategory.id)}
                            />
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={5}>
                          <p style={{textAlign:"center"}}>No Subcategories Found</p>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
          </Card>
        </Container>
      </Page>
    );
  }

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
