export const CustomformatDate = (dateString) => {
  const dateObject = new Date(dateString);
  const day = dateObject.getDate().toString().padStart(2, '0');
  const monthIndex = dateObject.getMonth(); // Note: Month is zero-based
  const year = dateObject.getFullYear();

  // Array of month abbreviations
  const monthAbbreviations = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  return `${day}-${monthAbbreviations[monthIndex]}-${year}`;
};

export const CustomconvertTo12HourFormatString = (timeString) => {
  if (typeof timeString === 'string') {
    const [hours, minutes] = timeString.split(':');
    let period = 'AM';

    let formattedHours = parseInt(hours, 10);
    if (formattedHours === 0) {
      formattedHours = 12; // Handle midnight (00:xx) as 12 AM
    } else if (formattedHours === 12) {
      period = 'PM'; // Handle noon (12:xx) as 12 PM
    } else if (formattedHours > 12) {
      formattedHours -= 12;
      period = 'PM';
    }

    // Ensure the formatted hours are displayed with leading zeros if needed
    formattedHours = formattedHours.toString().padStart(2, '0');

    return `${formattedHours}:${minutes} ${period}`;
  }

  // Return the original value if it's not a valid string
  return timeString;
};


export const CustomconvertTo12HourFormatArray = (timeArray) => {
  console.log(timeArray)
    if (Array.isArray(timeArray) && timeArray.length === 1) {
        const [timeString] = timeArray;
        const [hours, minutes, seconds] = timeString.split(':');
        let period = 'AM';

        let formattedHours = parseInt(hours, 10);

        if (formattedHours === 0) {
            formattedHours = 12; // Midnight
        } else if (formattedHours > 12) {
            formattedHours -= 12;
            period = 'PM';
        }

        // Ensure the formatted hours and minutes are displayed with leading zeros if needed
        formattedHours = formattedHours.toString().padStart(2, '0');
        const formattedMinutes = minutes.padStart(2, '0');
console.log(formattedHours)
        return `${formattedHours}:${formattedMinutes} ${period}`;
    }

    // Return the original value if it's not a valid array
    return timeArray;
};