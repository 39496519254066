import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
// MUI Components
import { Box, Button, Container, TextField, Typography, Card, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// Components
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getCategory, getSubCategory, updateSubCategory } from 'src/redux/slices/category';

export default function EditSubCategory() {
  const { enqueueSnackbar } = useSnackbar();
  const { id, subcategoryId } = useParams();
  console.log(id)
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { category,subCategories } = useSelector((state) => ({ ...state.category }));

  const [formData, setFormData] = useState({
    name: '',
    description: '',
    priority: '',
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    // Fetch subcategories when the component loads
    dispatch(getSubCategory(id));
  }, [dispatch, id]);
  console.log(subCategories)
  useEffect(() => {
    if (subCategories?.id) {
      // Find the specific subcategory to edit
        setFormData({
          name: subCategories.name || '',
          description: subCategories.description || '',
        });
      
    }
  }, [subCategories, subcategoryId])

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const data={
        ...formData,
        // active:false
    }
    try {
      await dispatch(updateSubCategory( id, data ));
      enqueueSnackbar('Subcategory updated successfully', { variant: 'success' });
      navigate(-1); // Navigate back to the previous page
    } catch (error) {
      enqueueSnackbar('Failed to update subcategory', { variant: 'error' });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Page title="Edit Subcategory">
      <Container maxWidth="lg">
        <HeaderBreadcrumbs
          heading="Edit Subcategory"
          links={[
            { name: 'Dashboard', href: '/' },
            { name: 'Category', href: '/dashboard/category' },
            { name: 'Edit Subcategory' },
          ]}
        />
        <Card sx={{ p: 3 }}>
          <form onSubmit={handleSubmit}>
            <Stack spacing={3}>
              <TextField
                fullWidth
                label="Name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                required
              />
              <TextField
                fullWidth
                label="Description"
                name="description"
                value={formData.description}
                onChange={handleInputChange}
                multiline
                rows={4}
              />
              {/* <TextField
                fullWidth
                label="Priority"
                name="priority"
                value={formData.priority}
                onChange={handleInputChange}
              /> */}
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                <Button
                  variant="outlined"
                  color="inherit"
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </Button>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  Save Changes
                </LoadingButton>
              </Box>
            </Stack>
          </form>
        </Card>
      </Container>
    </Page>
  );
}
