import PropTypes from 'prop-types';
/* eslint-disable */
import { paramCase } from 'change-case';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import {
  MenuItem,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Button,
  TextField,
  Stack,
} from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../../routes/paths';
// components
import Iconify from '../../../../components/Iconify';
import MenuPopover from '../../../../components/MenuPopover';

// ----------------------------------------------------------------------

SubCategoryMoreMenu.propTypes = {
  onDelete: PropTypes.func,
  name: PropTypes.string,
  id: PropTypes.string,
};

export default function SubCategoryMoreMenu({ onDelete, name, id }) {
  const [openMenu, setOpenMenu] = useState(null);
  const [openDialog, setOpenDialog] = useState(false); // State for dialog
  const [openEditDialog, setOpenEditDialog] = useState(false); // State for edit dialog
  const [editItem, setEditItem] = useState(null); // Item being edited
  const [listItems, setListItems] = useState([
    { id: 1, name: 'Item 1', description: 'This is the description for Item 1' },
    { id: 2, name: 'Item 2', description: 'This is the description for Item 2' },
    { id: 3, name: 'Item 3', description: 'This is the description for Item 3' },
  ]);

  const handleMenuOpen = (event) => {
    setOpenMenu(event.currentTarget);
  };

  const handleMenuClose = () => {
    setOpenMenu(null);
  };

  const handleDialogOpen = () => {
    setOpenDialog(true);
    handleMenuClose();
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleDeleteItem = (id) => {
    setListItems((prevItems) => prevItems.filter((item) => item.id !== id));
  };

  const handleEditDialogOpen = (item) => {
    setEditItem(item);
    setOpenEditDialog(true);
  };

  const handleEditDialogClose = () => {
    setEditItem(null);
    setOpenEditDialog(false);
  };

  const handleEditSubmit = () => {
    setListItems((prevItems) =>
      prevItems.map((item) =>
        item.id === editItem.id ? { ...item, name: editItem.name, description: editItem.description } : item
      )
    );
    handleEditDialogClose();
  };

  const handleEditChange = (field, value) => {
    setEditItem((prev) => ({ ...prev, [field]: value }));
  };

  const ICON = {
    mr: 2,
    width: 20,
    height: 20,
  };

  return (
    <>
      <IconButton onClick={handleMenuOpen}>
        <Iconify icon={'eva:more-vertical-fill'} width={20} height={20} />
      </IconButton>

      <MenuPopover
        open={Boolean(openMenu)}
        anchorEl={openMenu}
        onClose={handleMenuClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        arrow="right-top"
        sx={{
          mt: -1,
          width: 160,
          '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
        }}
      >
        <MenuItem
          component={RouterLink}
          to={`${PATH_DASHBOARD.category.root}/${paramCase(name)}/${id}/sub-category`}
        >
          <Iconify icon={'material-symbols:docs-add-on-rounded'} sx={{ ...ICON }} />
          Create
        </MenuItem>

        <MenuItem            component={RouterLink}
         to={`${PATH_DASHBOARD.category.root}/${paramCase(name)}/${id}`}
>
          <Iconify icon={'material-symbols:format-list-bulleted-rounded'} sx={{ ...ICON }} />
          List
        </MenuItem>
      </MenuPopover>

      {/* View Dialog */}
      <Dialog open={openDialog} onClose={handleDialogClose} fullWidth maxWidth="sm">
        <DialogTitle>Sub Categories for {name}</DialogTitle>
        <DialogContent>
          <List>
            {listItems.map((item) => (
              <ListItem key={item.id} alignItems="flex-start">
                <ListItemText
                  primary={item.name}
                  secondary={item.description}
                  primaryTypographyProps={{ fontWeight: 'bold' }}
                />
                <ListItemSecondaryAction>
                  <IconButton edge="end" onClick={() => handleEditDialogOpen(item)}>
                    <Iconify icon="material-symbols:edit" color="grey" />
                  </IconButton>
                  <IconButton edge="end" onClick={() => handleDeleteItem(item.id)}>
                    <Iconify icon="mdi:delete" color="red" />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Close</Button>
        </DialogActions>
      </Dialog>

      {/* Edit Dialog */}
      <Dialog open={openEditDialog} onClose={handleEditDialogClose} fullWidth maxWidth="sm">
        <DialogTitle>Edit Item</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <TextField
              label="Name"
              value={editItem?.name || ''}
              onChange={(e) => handleEditChange('name', e.target.value)}
              fullWidth
            />
            <TextField
              label="Description"
              value={editItem?.description || ''}
              onChange={(e) => handleEditChange('description', e.target.value)}
              fullWidth
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditDialogClose}>Cancel</Button>
          <Button onClick={handleEditSubmit} variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
