import { createSlice } from '@reduxjs/toolkit';
/* eslint-disable */
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// utils
import { axiosAdmin } from '../../utils/axios';
//
import { dispatch } from '../store';
import { isValidToken, setSession } from '../../utils/jwt';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  //   categories: [],
  slotSlots: [],
  singleSlot:{
  },
  // category: '',
  tableSlot: '',
  filters: {
    name: [],
  },
  selectedCatId: null,
  count: null,
  pages: null,
  skip: null,
  //   sortBy: null,
  //   filters: {
  //     gender: [],
  //     category: 'All',
  //     colors: [],
  //     priceRange: '',
  //     rating: '',
  //   },
  //   checkout: {
  //     activeStep: 0,
  //     cart: [],
  //     subtotal: 0,
  //     total: 0,
  //     discount: 0,
  //     shipping: 0,
  //     billing: null,
  //   },
};

const slice = createSlice({
  name: 'slotSlot',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    gettableSlotsSuccess(state, action) {
      state.isLoading = false;
      state.tables = action.payload.list;
      state.count = action.payload.count;
      state.pages = action.payload.page;
      state.skip = action.payload.skip;
    },

    deletetableSlotsSuccess(state, action) {
      state.isLoading = false;
      state.tables = action.payload;
    },

    // GET PRODUCT
    gettableSlotSuccess(state, action) {
      state.isLoading = false;
      state.table = action.payload;
    },

    filtertable(state, action) {
      //  state.filters.name = action.payload.name;
      state.tables = action.payload;
    },

    sortByProducts(state, action) {
      state.sortBy = action.payload;
    },

    // CHECKOUT

    deletetableSuccess(state, action) {
      //     const { id } = action.payload;
      //  state.categories =  state.categories.filter((category) => category.id !== id);
      state.isLoading = false;
      state.tables = action.payload;
    },

    selectTable(state, action) {
      const catId = action.payload;
      state.isOpenModal = true;
      state.selectedCatId = catId;
    },

    createSlotSlotSuccess(state, action) {
      const newCategory = action.payload;
      state.isLoading = false;
      state.slotSlots = [...state.slotSlots, newCategory];
    },

    getSlotSlotSuccessAll(state, action) {
      state.isLoading = false;
      state.slotSlots = action.payload;
    },
     getSingleSlotSuccessAll(state, action) {
      state.isLoading = false;
      state.singleSlot = action.payload;
    },

    
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { gettableSlotSuccess, selectTable, filtertable, sortByProducts } = slice.actions;

// ----------------------------------------------------------------------

export function getSlotSlotssAll(params) {
  return async () => {
    console.log(params);
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = window.localStorage.getItem('accessToken');
      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);
        const response = await axiosAdmin.get(`/slot-creation/store/${params}`);
        console.log(response);
        dispatch(slice.actions.getSlotSlotSuccessAll(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSingleSlotbyId(params) {
  return async () => {
    console.log(params);
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = window.localStorage.getItem('accessToken');
      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);
        const response = await axiosAdmin.get(`/slot-creation/store/slot/${params}`);
        console.log(response);
        dispatch(slice.actions.getSingleSlotSuccessAll(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function updateSlotBySlotId(params,callBack) {
  return async () => {
    console.log(params);
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = window.localStorage.getItem('accessToken');
      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);
        const response = await axiosAdmin.put(`/slot-creation/update/${params.slotId}`,params);
        callBack(false);
        console.log(response);
        // dispatch(slice.actions.getSingleSlotSuccessAll(response.data));
      }
    } catch (error) {
      callBack(error);

      dispatch(slice.actions.hasError(error));
    }
  };
}
 
export function createSlotslot(newSlotslot, callBack) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      console.log(newSlotslot);
      const accessToken = window.localStorage.getItem('accessToken');
      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);
        const response = await axiosAdmin.post('/slot-creation', newSlotslot);
        callBack(false);
        dispatch(slice.actions.createSlotSlotSuccess(response.data));
      }
    } catch (error) {
      callBack(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getTables(params) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = window.localStorage.getItem('accessToken');

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        const response = await axiosAdmin.get('/categories?', {
          params,
        });

        dispatch(slice.actions.gettablesSuccess(response.data));
        // alert(JSON.stringify(response.data))
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getTable(id) {
  return async () => {
    dispatch(slice.actions.startLoading());

    try {
      const accessToken = window.localStorage.getItem('accessToken');

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        const response = await axiosAdmin.get(`/categories/${id}`);
        dispatch(slice.actions.getCategorySuccess(response.data));
        // alert(JSON.stringify(response.data));
      }
    } catch (error) {
      //alert(JSON.stringify(error));
      // console.error('error' +JSON.stringify(error));
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function updateSlotSlotStatus(id, status) {
  console.log(id);
  console.log(status);
  const s = {
    slotStatus: status,
  };
  console.log(s);
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = window.localStorage.getItem('accessToken');
      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);
        const response = await axiosAdmin.put(`/clinic-slots/${id}`, s);
        console.log(response);
        // dispatch(slice.actions.getCategorySuccess(response.data));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function deleteSlotSlots(slotID, callBack) {
  // alert(slotID)
  console.log(slotID);
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = window.localStorage.getItem('accessToken');
      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);
        const response = await axiosAdmin.delete(`/slot-creation/delete/${slotID}`);
        callBack(false);
      }
    } catch (error) {
      callBack(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteManyTables(storeId, callBack) {
  console.log(storeId);
  // alert(offerId)
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = window.localStorage.getItem('accessToken');
      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);
        const response = await axiosAdmin.post('/clinic-slots/remove-many', storeId);
        console.log(response);
        callBack(false);
      }
    } catch (error) {
      console.log(error);
      callBack(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// import { createSlice } from '@reduxjs/toolkit';
// /* eslint-disable */
// import sum from 'lodash/sum';
// import uniqBy from 'lodash/uniqBy';
// import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// // utils
// import { axiosAdmin } from '../../utils/axios';
// //
// import { dispatch } from '../store';
// import { isValidToken, setSession } from '../../utils/jwt';
// // ----------------------------------------------------------------------

// const initialState = {
//   isLoading: false,
//   error: null,
//   //   categories: [],
//   slotSlots: [],
//   // category: '',
//   tableSlot: '',
//   filters: {
//     name: [],
//   },
//   selectedCatId: null,
//   count: null,
//   pages: null,
//   skip: null,
//   //   sortBy: null,
//   //   filters: {
//   //     gender: [],
//   //     category: 'All',
//   //     colors: [],
//   //     priceRange: '',
//   //     rating: '',
//   //   },
//   //   checkout: {
//   //     activeStep: 0,
//   //     cart: [],
//   //     subtotal: 0,
//   //     total: 0,
//   //     discount: 0,
//   //     shipping: 0,
//   //     billing: null,
//   //   },
// };

// const slice = createSlice({
//   name: 'slotSlot',
//   initialState,
//   reducers: {
//     // START LOADING
//     startLoading(state) {
//       state.isLoading = true;
//     },

//     // HAS ERROR
//     hasError(state, action) {
//       state.isLoading = false;
//       state.error = action.payload;
//     },

//     // GET PRODUCTS
//     gettableSlotsSuccess(state, action) {
//       state.isLoading = false;
//       state.tables = action.payload.list;
//       state.count = action.payload.count;
//       state.pages = action.payload.page;
//       state.skip = action.payload.skip;
//     },

//     deletetableSlotsSuccess(state, action) {
//       state.isLoading = false;
//       state.tables = action.payload;
//     },

//     // GET PRODUCT
//     gettableSlotSuccess(state, action) {
//       state.isLoading = false;
//       state.table = action.payload;
//     },

//     filtertable(state, action) {
//       //  state.filters.name = action.payload.name;
//       state.tables = action.payload;
//     },

//     sortByProducts(state, action) {
//       state.sortBy = action.payload;
//     },

//     // CHECKOUT

//     deletetableSuccess(state, action) {
//       //     const { id } = action.payload;
//       //  state.categories =  state.categories.filter((category) => category.id !== id);
//       state.isLoading = false;
//       state.tables = action.payload;
//     },

//     selectTable(state, action) {
//       const catId = action.payload;
//       state.isOpenModal = true;
//       state.selectedCatId = catId;
//     },

//     createSlotSlotSuccess(state, action) {
//       const newCategory = action.payload;
//       state.isLoading = false;
//       state.slotSlots = [...state.slotSlots, newCategory];
//     },

//     getSlotSlotSuccessAll(state, action) {
//       state.isLoading = false;
//       state.slotSlots = action.payload;
//     },
//   },
// });

// // Reducer
// export default slice.reducer;

// // Actions
// export const { gettableSlotSuccess, selectTable, filtertable, sortByProducts } = slice.actions;

// // ----------------------------------------------------------------------

// export function getSlotSlotssAll(params) {
//   return async () => {
//     console.log(params);
//     dispatch(slice.actions.startLoading());
//     try {
//       const accessToken = window.localStorage.getItem('accessToken');
//       if (accessToken && isValidToken(accessToken)) {
//         setSession(accessToken);
//         const response = await axiosAdmin.get(`slot-creation/store/${params}`);
//         console.log(response);
//         dispatch(slice.actions.getSlotSlotSuccessAll(response.data));
//       }
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

// export function createSlotslot(newSlotslot, callBack) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       console.log(newSlotslot);
//       const accessToken = window.localStorage.getItem('accessToken');
//       if (accessToken && isValidToken(accessToken)) {
//         setSession(accessToken);
//         const response = await axiosAdmin.post('/slot-creation', newSlotslot);
//         console.log(response)
//         callBack(false);
//         dispatch(slice.actions.createSlotSlotSuccess(response.data));
//       }
//     } catch (error) {
//       callBack(error);
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

// export function getTables(params) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const accessToken = window.localStorage.getItem('accessToken');

//       if (accessToken && isValidToken(accessToken)) {
//         setSession(accessToken);

//         const response = await axiosAdmin.get('/categories?', {
//           params,
//         });

//         dispatch(slice.actions.gettablesSuccess(response.data));
//         // alert(JSON.stringify(response.data))
//       }
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

// export function getTable(id) {
//   return async () => {
//     dispatch(slice.actions.startLoading());

//     try {
//       const accessToken = window.localStorage.getItem('accessToken');

//       if (accessToken && isValidToken(accessToken)) {
//         setSession(accessToken);

//         const response = await axiosAdmin.get(`/categories/${id}`);
//         dispatch(slice.actions.getCategorySuccess(response.data));
//         // alert(JSON.stringify(response.data));
//       }
//     } catch (error) {
//       //alert(JSON.stringify(error));
//       // console.error('error' +JSON.stringify(error));
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }
// export function updateSlotSlotStatus(id, status) {
//   console.log(id)
//   console.log(status)
// const s={
//   slotStatus:status
// }
// console.log(s)
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const accessToken = window.localStorage.getItem('accessToken');
//       if (accessToken && isValidToken(accessToken)) {
//         setSession(accessToken);
//         const response = await axiosAdmin.patch(`/slot-creation/update-time-slots/${id}`, s);
//         console.log(response)
//         // dispatch(slice.actions.getCategorySuccess(response.data));
//       }
//     } catch (error) {
//       console.log(error)
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }
// // ----------------------------------------------------------------------

// export function deleteSlotSlots(slotID,callBack) {
//   // alert(slotID)
//   console.log(slotID)
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const accessToken = window.localStorage.getItem('accessToken');
//       if (accessToken && isValidToken(accessToken)) {
//         setSession(accessToken);
//         const response = await axiosAdmin.delete(`/clinic-slots/${slotID}`);
//         callBack(false);
//       }
//     } catch (error) {
//       callBack(error)
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

// export function deleteManyTables(storeId,callBack) {
//   console.log(storeId);
//   // alert(offerId)
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const accessToken = window.localStorage.getItem('accessToken');
//       if (accessToken && isValidToken(accessToken)) {
//         setSession(accessToken);
//         const response = await axiosAdmin.post('/clinic-slots/remove-many', storeId);
//         console.log(response);
//         callBack(false);
//       }
//     } catch (error) {
//       console.log(error);
//       callBack(error);
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }
