import { createSlice } from '@reduxjs/toolkit';
/* eslint-disable */
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// utils
import {axiosAdmin} from '../../utils/axios';
//
import { dispatch } from '../store';
import { isValidToken, setSession } from '../../utils/jwt';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  categories: [],
  subCategories:[],
    category: '',
    filters: {
      name: [],
     
    },
    selectedCatId: null,
    count: null,
    pages: null,
    skip:null
//   sortBy: null,
//   filters: {
//     gender: [],
//     category: 'All',
//     colors: [],
//     priceRange: '',
//     rating: '',
//   },
//   checkout: {
//     activeStep: 0,
//     cart: [],
//     subtotal: 0,
//     total: 0,
//     discount: 0,
//     shipping: 0,
//     billing: null,
//   },
};

const slice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getCategoriesSuccess(state, action) {
      state.isLoading = false;
      state.categories = action.payload.list;
      state.count = action.payload.count;
      state.pages = action.payload.page;
      state.skip = action.payload.skip;
    },

    deleteCategoriesSuccess(state, action) {
      state.isLoading = false;
      state.categories = action.payload;
     
    },

    // GET PRODUCT
    getCategorySuccess(state, action) {
      state.isLoading = false;
      state.category = action.payload;
    },

    getSubCategorySuccess(state, action) {
      state.isLoading = false;
      state.subCategories = action.payload;
    },

    
 
    filterCategory(state, action) {
    //  state.filters.name = action.payload.name;
    state.categories = action.payload;
    },
   
    sortByProducts(state, action) {
      state.sortBy = action.payload;
    },

    // CHECKOUT
     
    deleteCategorySuccess(state, action) {
  //     const { id } = action.payload;
  //  state.categories =  state.categories.filter((category) => category.id !== id);
  state.isLoading = false;
  state.categories = action.payload; 

      
    },
 
    
    selectCategory(state, action) {
      const catId = action.payload;
      state.isOpenModal = true;
      state.selectedCatId = catId;
    },
    

 
  

  
    createCategorySuccess(state, action) {
      const newCategory = action.payload;
      state.isLoading = false;
      state.categories = [...state.categories, newCategory];
    },
 

   
    getCategoriesSuccessAll(state, action) {
      state.isLoading = false;
      state.categories = action.payload;
       
    },
   

  
     
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
 getCategoriesSuccess,
 selectCategory,
 filterCategory,sortByProducts
} = slice.actions;

// ----------------------------------------------------------------------
 
export function getCategoriesAll(params) {
  return async () => {
 console.log(params)
    
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = window.localStorage.getItem('accessToken');
 
        
      
      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

      const response = await axiosAdmin.get('/categories?',{
        params
      });
      console.log(response)
    
      dispatch(slice.actions.getCategoriesSuccessAll(response.data));
    
  
      }
        
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function createCategory(newCategory) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = window.localStorage.getItem('accessToken');
 
        
      
      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);
      const response = await axiosAdmin.post('/categories', newCategory);
      dispatch(slice.actions.createCategorySuccess(response.data));
      }
    } catch (error) {
      //alert(JSON.stringify(error))
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function createSubCategory(newCategory) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = window.localStorage.getItem('accessToken');
 
        
      
      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);
      const response = await axiosAdmin.post('/subcategory', newCategory);
      dispatch(slice.actions.createCategorySuccess(response.data));
      }
    } catch (error) {
      //alert(JSON.stringify(error))
      dispatch(slice.actions.hasError(error));
    }
  };
}


// export function getCategories() {
//   return async () => {

//     alert('Category')
//     dispatch(slice.actions.startLoading());
//     try {
//       const accessToken = window.localStorage.getItem('accessToken');
 
        
      
//       if (accessToken && isValidToken(accessToken)) {
//         setSession(accessToken);

//       const response = await axiosAdmin.get('/categories');
    
//       dispatch(slice.actions.getCategoriesSuccess(response.data));
//       }
        
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }
 

export function getCategories(params) {
  return async () => {

    
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = window.localStorage.getItem('accessToken');
 
        
      
      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

      const response = await axiosAdmin.get('/categories?',{
        params
      });
    
      dispatch(slice.actions.getCategoriesSuccess(response.data));
     // alert(JSON.stringify(response.data))
  
      }
        
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCategory(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = window.localStorage.getItem('accessToken');
      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

      const response = await axiosAdmin.get(`/categories/${id}`);
      dispatch(slice.actions.getCategorySuccess(response.data));
     // alert(JSON.stringify(response.data));
      }
    } catch (error) {
      //alert(JSON.stringify(error));
     // console.error('error' +JSON.stringify(error));
      dispatch(slice.actions.hasError(error));
    }
  };

}


export function getSubCategory(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = window.localStorage.getItem('accessToken');
      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

      const response = await axiosAdmin.get(`/subcategory/${id}`);
      dispatch(slice.actions.getSubCategorySuccess(response.data));
     // alert(JSON.stringify(response.data));
      }
    } catch (error) {
      //alert(JSON.stringify(error));
     // console.error('error' +JSON.stringify(error));
      dispatch(slice.actions.hasError(error));
    }
  };

}

export function updatCategory(id, updateCategory) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = window.localStorage.getItem('accessToken');
      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

      const response = await axiosAdmin.put(`/categories/${id}`,updateCategory);
      dispatch(slice.actions.getCategorySuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function updateSubCategory(id, updateCategory) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = window.localStorage.getItem('accessToken');
      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

      const response = await axiosAdmin.patch(`/subcategory/${id}`,updateCategory);
      dispatch(slice.actions.getCategorySuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

 
export function deleteCategory(categoryID) {

 // alert(categoryID)
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = window.localStorage.getItem('accessToken');
     if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

    const response =   await axiosAdmin.delete(`/categories/${categoryID}`);
      // dispatch(slice.actions.deleteCategoriesSuccess(response.data));
     }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteSubCategory(categoryID) {

  // alert(categoryID)
   return async () => {
     dispatch(slice.actions.startLoading());
     try {
      console.log(categoryID)
       const accessToken = window.localStorage.getItem('accessToken');
      if (accessToken && isValidToken(accessToken)) {
         setSession(accessToken);
 
     const response =   await axiosAdmin.delete(`/subcategory/${categoryID}`);
       dispatch(slice.actions.deleteCategoriesSuccess(response.data));
      }
     } catch (error) {
       dispatch(slice.actions.hasError(error));
     }
   };
 }
 





 
export function deleteManyCategory(storeId) {

  // alert(offerId)
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
      const response =   await axiosAdmin.post('/categories/remove-many',storeId);
    
        dispatch(slice.actions.deleteCategorySuccess(response.data));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }