import jwtDecode from 'jwt-decode';
/* eslint-disable */
import { verify, sign } from 'jsonwebtoken';
import { axiosInstance as axios, axiosAdmin } from '../utils/axios';

// ----------------------------------------------------------------------

const isValidToken = (accessToken) => {
  if (!accessToken) {
    window.location.href = '/'; // Redirect if no token
    return false;
  }

  try {
    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;

    if (decoded.exp > currentTime) {
      return true;
    } else {
      handleExpiredToken();
      return false;
    }
  } catch (error) {
    console.error('Invalid token:', error);
    handleExpiredToken();
    return false;
  }
};

// Handle token expiration
const handleExpiredToken = () => {
  localStorage.removeItem('accessToken');
  delete axios.defaults.headers.common.Authorization;
  delete axiosAdmin.defaults.headers.common.Authorization;
  window.location.href = '/'; // Redirect to login page
};

// ----------------------------------------------------------------------

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    axiosAdmin.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    handleExpiredToken();
  }
};

export { isValidToken, setSession, verify, sign };
