import { sentenceCase } from 'change-case';
/* eslint-disable */
import { Link, Link as RouterLink, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import {
  Button,
  Box,
  Card,
  Table,
  TableRow,
  Checkbox,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Alert,
  TableHead,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  Grid,
  IconButton,
  TableSortLabel,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@mui/material';
// redux
import { useDispatch, useSelector } from '../../../redux/store';
import { getCategories, deleteCategory, deleteManyCategory } from '../../../redux/slices/category';
// utils
import { fDate } from '../../../utils/formatTime';
import { fCurrency } from '../../../utils/formatNumber';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// hooks
import useSettings from '../../../hooks/useSettings';
// components
import Page from '../../../components/Page';
import Label from '../../../components/Label';
import Image from '../../../components/Image';
import Scrollbar from '../../../components/Scrollbar';
import SearchNotFound from '../../../components/SearchNotFound';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import Iconify from '../../../components/Iconify';
import swal from 'sweetalert';

// sections
import {
  CategoryMoreMenu,
  CategoryListHead,
  CategoryListToolbar,
} from '../../../sections/@dashboard/category/category-list/index';
import { deleteSlotSlots, getSlotSlotssAll, updateSlotSlotStatus } from 'src/redux/slices/slotSlot';
import SlotListToolbar from 'src/sections/@dashboard/booking/SlotBooking/SlotListToolbar';
import DeleteIcon from '@mui/icons-material/Delete';
import NoDataFound from 'src/components/NoDataFound';
import { getSlotBooking, updateSlotBookingStatus } from 'src/redux/slices/slotBooking';
import {
  CustomconvertTo12HourFormatArray,
  CustomconvertTo12HourFormatString,
  CustomformatDate,
} from 'src/components/formatChanger/FormatChnager';
import { getTableBooking, getTableBookingOrders, updateTableBookingStatus } from 'src/redux/slices/booking';

// ../../sections/@dashboard/e-commerce/category-list/
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'priority', label: 'Order', alignRight: false },
  { id: 'createdAt', label: 'Create at', alignRight: false },
  { id: 'action', label: 'Action', alignRight: true },
];

// ----------------------------------------------------------------------

export default function OrderListTableBooking() {
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  const handleOpenDialog = (items) => {
    setSelectedItems(items);
    setOpenDialog(true);
  };


  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedItems([]);
  };


  const { themeStretch } = useSettings();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const dispatch = useDispatch();
  const clinicSlots = useSelector((state) => state.slotSlot.slotSlots);
  const { name, storeid } = useParams();

  const [categoryList, setCategoryList] = useState([]);
  const [filterName, setFilterName] = useState('');
  const [bookedDates, setBookedDates] = useState([]);
  const [selected, setSelected] = useState([]);
  const [tables, setTables] = useState([]);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('Date');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelect = (clinic_slot_id) => {
    console.log(clinic_slot_id);
    const selectedIndex = selected.indexOf(clinic_slot_id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = [...selected, clinic_slot_id];
    } else {
      newSelected = [...selected];
      newSelected.splice(selectedIndex, 1);
    }
    setSelected(newSelected);
  };

  const handleSelectAll = () => {
    if (selected.length === bookedDates.length) {
      setSelected([]);
    } else {
      const newSelected = bookedDates.map((row) => row.id);
      console.log(newSelected);
      setSelected(newSelected);
    }
  };


  const slotBookingsList = useSelector((state) => state.booking.orderList);

  useEffect(() => {
    const params=storeid
    // const params = 22;
    dispatch(getTableBookingOrders(params));
  }, [dispatch]);

  useEffect(() => {
    if (slotBookingsList.length) {
      console.log('RAMO', slotBookingsList);
      setBookedDates(slotBookingsList);
      console.log(bookedDates);
    } else {
      console.log('first');
      setBookedDates([]);
    }
  }, [slotBookingsList, dispatch]);
  useEffect(() => {
    const params = storeid;
    dispatch(getSlotSlotssAll(params));
  }, [dispatch]);

  useEffect(() => {
    if (clinicSlots.length) {
      console.log('RAMO', clinicSlots);
      setTables(clinicSlots);
    } else {
      console.log('first');
      setTables([]);
    }
  }, [clinicSlots, dispatch]);


  const handleDeleteProducts = (selected) => {
    console.log(selected);
    try {
      swal({
        title: 'Delete Slot Timing',
        text: 'Are you sure you want to delete the selected slot timings? This action cannot be undone.',
        icon: 'warning',
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          const deletedIds = selected.map((id) => id);
          const deleteProducts = tables.filter((table) => !deletedIds.includes(table.clinic_slot_id));

          console.log(deleteProducts);

          setSelected([]);
          setTables(deleteProducts);

          swal('Success!', 'The selected slot timings has been successfully deleted.', {
            icon: 'success',
          });
        } else {
          swal('Cancelled', 'Your slot timings are safe!', 'info');
        }
      });
    } catch (error) {
      console.error(error);
    }
  };




  const cellStyle = {
    fontSize: '16px',
    textAlign: 'center',
    // Adjust the font size as needed
  };

  const [sortedData, setSortedData] = useState(bookedDates);
  useEffect(() => {
    console.log(sortedData);
    const sorted = [...bookedDates].sort(
      (a, b) => {
        const isAsc = order === 'asc';
        if (orderBy === 'Date') {
          return (isAsc ? 1 : -1) * (new Date(a.bookingDate) - new Date(b.bookingDate));
        }
        // Add more cases for other columns if needed
      },
      [dispatch, slotBookingsList]
    );

    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    setSortedData(sorted.slice(startIndex, endIndex));
  }, [tables, order, orderBy, page, rowsPerPage]);

  return (
    <Page title="Slot List">
        <Page title="Create a new Booking">
            <Container maxWidth={themeStretch ? false : 'lg'}>
             
                 <HeaderBreadcrumbs
                action={
                  <>
                    <div style={{ display: 'flex', gap: '10px' }}>
                      <nav role="navigation">
                        <ul className="unique-nav">
                          <li className="unique-li">
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                              <Iconify style={{ width: '20px', height: '20px' }} icon={'octicon:move-to-end-16'} />
                              <div className="unique-a font-xl">Move to</div>
                            </div>
      
                            <ul className="unique-dropdown">
                            <Link
                                style={{ display: 'flex', alignItems: 'center', justifyContent: 'left', gap: '1px' }}
                                className="unique-a font-sm "
                                to={`${PATH_DASHBOARD.TableBooking.root}/${name}/${storeid}/list`}
                              >
                                <li
                                  className="unique-li"
                                  style={{ whiteSpace: 'nowrap', display: 'flex', alignItems: 'center', gap: '10px' }}
                                >
                                  <Iconify icon={'carbon:list'} />
                                  <h4> Booking list</h4>{' '}
                                </li>
                              </Link>
                              <Link
                                style={{ display: 'flex', alignItems: 'center', justifyContent: 'left', gap: '1px' }}
                                className="unique-a font-sm "
                                to={`${PATH_DASHBOARD.TableBooking.root}/${name}/${storeid}/bookinghistory`}
                              >
                                <li
                                  className="unique-li"
                                  style={{ whiteSpace: 'nowrap', display: 'flex', alignItems: 'center', gap: '10px' }}
                                >
                                <Iconify icon={'mdi:history'} />
                                  <h4> Booking History</h4>{' '}
                                </li>
                              </Link>
                          
                              <Link
                                style={{ display: 'flex', alignItems: 'center', justifyContent: 'left', gap: '1px' }}
                                className="unique-a font-sm "
                                to={`${PATH_DASHBOARD.TableBooking.root}/${name}/${storeid}/addslot`}
                              >
                                <li
                                  className="unique-li"
                                  style={{ whiteSpace: 'nowrap', display: 'flex', alignItems: 'center', gap: '10px' }}
                                >
                                  <Iconify icon={'gridicons:create'} />
                                  <h4>Create Slot</h4>{' '}
                                </li>
                              </Link>
                              
                              <Link
                                style={{ display: 'flex', alignItems: 'center', justifyContent: 'left', gap: '1px' }}
                                className="unique-a font-sm "
                                to={`${PATH_DASHBOARD.TableBooking.root}/${name}/${storeid}/listslotcalendar`}
                              >
                                <li
                                  className="unique-li"
                                  style={{ whiteSpace: 'nowrap', display: 'flex', alignItems: 'center', gap: '10px' }}
                                >
                                  <Iconify icon={'carbon:list'} />
                                  <h4>List slot</h4>{' '}
                                </li>
                              </Link>
                              {/* <Link
                                style={{ display: 'flex', alignItems: 'center', justifyContent: 'left', gap: '1px' }}
                                className="unique-a font-sm "
                                to={`${PATH_DASHBOARD.TableBooking.root}/${name}/${storeid}/addbooking`}
                              >
                                <li
                                  className="unique-li"
                                  style={{ whiteSpace: 'nowrap', display: 'flex', alignItems: 'center', gap: '10px' }}
                                >
                                  <Iconify icon={'gridicons:create'} />
                                  <h4>Create Booking </h4>{' '}
                                </li>
                              </Link> */}
                            
                           
                              <Link
                                style={{ display: 'flex', alignItems: 'center', justifyContent: 'left', gap: '10px' }}
                                className="unique-a font-sm "
                                to={`${PATH_DASHBOARD.table.root}/${name}/${storeid}/addtable`}
                              >
                                <li
                                  className="unique-li"
                                  style={{ whiteSpace: 'nowrap', display: 'flex', alignItems: 'center', gap: '10px' }}
                                >
                                  <Iconify icon={'gridicons:create'} />
                                  <h4>Create Table</h4>
                                </li>
                              </Link>
                              <Link
                                style={{ display: 'flex', alignItems: 'center', justifyContent: 'left', gap: '1px' }}
                                className="unique-a font-sm "
                                to={`${PATH_DASHBOARD.table.root}/${name}/${storeid}/listtable`}
                              >
                                <li
                                  className="unique-li"
                                  style={{ whiteSpace: 'nowrap', display: 'flex', alignItems: 'center', gap: '10px' }}
                                >
                                  <Iconify icon={'carbon:list'} />
                                  <h4>List Table</h4>{' '}
                                </li>
                              </Link>
                             
                            </ul>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </>
                }
                heading={'Order List'}
                links={[
                  { name: 'Dashboard', href: PATH_DASHBOARD.general.app },
                  {
                    name: 'Store',
                    href:`${PATH_DASHBOARD.TableBooking.root}/${name}/${storeid}/list`,
                  },
                  { name: name },
                ]}
              />
            </Container>
          </Page>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <Card>
          <SlotListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onDeleteProducts={() => handleDeleteProducts(selected)}
          />

<>
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <TableHead>
              <TableRow style={{ height: '80px' }}>
                <TableCell style={{ fontSize: '16px', textAlign: 'center', minWidth: 80 }}>Sl no</TableCell>
                <TableCell style={{ fontSize: '16px', textAlign: 'center', minWidth: 160 }}>
                  <TableSortLabel
                    active={orderBy === 'Date'}
                    direction={orderBy === 'Date' ? order : 'asc'}
                    onClick={() => handleRequestSort('Date')}
                  >
                    Date
                  </TableSortLabel>
                </TableCell>
                <TableCell style={cellStyle}>Total Amount</TableCell>
                <TableCell style={cellStyle}>Order Items</TableCell>
                <TableCell style={cellStyle}>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {bookedDates.length > 0 ? (
                bookedDates.map((order, index) => (
                  <TableRow key={order.id} style={{ height: '80px' }} hover>
                    <TableCell style={cellStyle}>{index + 1}</TableCell>
                    <TableCell style={cellStyle}>{new Date(order.created_at).toLocaleDateString()}</TableCell>
                    <TableCell style={cellStyle}>₹{order.total}</TableCell>
                    <TableCell 
  style={{ cursor: "pointer", ...cellStyle }} 
  onClick={() => handleOpenDialog(order.orderItems)}
>
  <Iconify icon="tabler:eye" width={30} height={25} />
</TableCell>
                    <TableCell style={{ textAlign: 'center' }}>
                      <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                          <InputLabel id={`status-label-${order.id}`} style={{ fontSize: '16px', color: 'grey' }}>
                            Select status
                          </InputLabel>
                          <Select
                            labelId={`status-label-${order.id}`}
                            id={`status-select-${order.id}`}
                            value={order.status}
                            onChange={(e) => console.log(`Status changed for Order ID ${order.id}:`, e.target.value)}
                          >
                            <MenuItem value="pending">Pending</MenuItem>
                            <MenuItem value="completed">Completed</MenuItem>
                            <MenuItem value="cancelled">Cancelled</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow style={{ height: 53 * 0 }}>
                  <TableCell align="center" colSpan={6}>
                    <Box sx={{ py: 3 }}>No Order Data Found</Box>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={bookedDates.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(e, newPage) => setPage(newPage)}
            onRowsPerPageChange={(e) => {
              setRowsPerPage(parseInt(e.target.value, 10));
              setPage(0);
            }}
          />
        </TableContainer>
      </Scrollbar>

      {/* Dialog for Order Items */}
      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
        <DialogTitle>Ordered Items</DialogTitle>
        <DialogContent>
          {selectedItems.map((item) => (
            <div key={item.id}>
              Quantity: {item.quantity}, Price: ₹{item.price}
            </div>
          ))}
        </DialogContent>
      </Dialog>
    </>
        </Card>
      </Container>
    </Page>
  );
}

// ----------------------------------------------------------------------
